<template>
  <div class="page-header">
    <div class="page-title" style="margin: 10px 0">
      <h3>{{ campaign ? formatCampaignName(campaign.google_campaign_name): " &nbsp;" }}</h3>
    </div>
  </div>

  <div class="box box-shadow bg-white mb-2 p-3 rounded">
    <div class="row">
      <div class="col-md-4 col-4 mb-md-0 mb-3">
        <div  class="form-control" id="reportrange">
          <i class="flaticon-calendar"></i>&nbsp;
          <span></span>
        </div>
      </div>

      <div class="col-md-2 col-4 mb-md-0 mb-3">
        <select class="form-select form-control"
                v-model="queryParams.profileId"
                :disabled="loading || loadingMetrics"
                @change="getAmazonCampaigns"
                ref="dashboardAdAccount">
          <option value="">Select Ad Account</option>
          <option :value="account.profileId"
                  v-for="account in amazonAdAccounts"
                  :key="'ac_'+account.profileId">{{ account.accountInfo.name }}</option>
        </select>
      </div>

      <div class="col-md-3">
        <select class="form-select form-control"
                v-model="queryParams.ad_group_id">
          <option value="">All Ad Groups</option>
          <option :value="adGroup.id"
                  v-for="adGroup in adGroups"
                  :key="'adGroup_'+adGroup.id">{{ adGroup.name }}</option>
        </select>
      </div>

      <div class="col-md-3">
        <span class="text-info float-right">Total Keyword In Tracker: <b>{{ trackingKeywordCount }}</b> (Max: 7)</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <button class="btn btn-info mr-3" @click="getGoogleAdDetails">
          <i class="fas fa-info"></i> Campaign Info
        </button>

        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <button class="btn btn-primary"
                  v-if="campaign"
                  @click="$refs.addGoogleKeyword.show()">
            <i class="fas fa-plus"></i> Add Keyword
          </button>

          <div class="btn-group" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Export
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
              <a class="dropdown-item"
                 @click.prevent="exportData('xlsx')"
                 href="#">Excel</a>
              <a class="dropdown-item"
                 @click.prevent="exportData('csv')"
                 href="#">CSV</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 text-right" v-if="campaign">
        Created At: {{ campaign.created_at }}
      </div>
    </div>
  </div>

  <div class="statbox widget box box-shadow">
    <div class="widget-content widget-content-area rounded">
      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive"
               ref="keywords-table-container"
               style="overflow-y: auto; height: calc(100vh - 353px)">
            <table class="tablesaw table-striped"
                   id="keywords-table">
              <thead>
              <tr class="pb-5">
                <th :colspan="3 + columns.filter(c => c.show).length"
                    style="font-weight: bold; font-size: 15px">
                  Keywords

                  <button class="ml-2 btn btn-sm btn-dark p-1"
                          href="#"
                          @click.prevent="$refs.keywordTableSettings.show()">
                    <i class="fa-solid fa-gear"></i> Settings
                  </button>



                  <button class="ml-1 btn btn-sm btn-dark p-1"
                          href="#"
                          v-if="selectedKeywords.length"
                          title="Pause"
                          @click.prevent="changeBulkStatus">
                    <i class="fa-solid fa-pause"></i> Pause
                  </button>

                  <button class="ml-1 btn btn-sm btn-dark p-1"
                          href="#"
                          v-if="selectedKeywords.length && googleCampaign && googleCampaign.bidding_strategy === 'Manual CPC'"
                          title="Change Max CPC"
                          @click.prevent="changeBulkMaxCpc">
                    <i class="fa-solid fa-dollar-sign"></i> Change Max CPC
                  </button>

                  <button class="ml-1 btn btn-sm btn-danger p-1"
                          href="#"
                          v-if="selectedKeywords.length"
                          title="Delete"
                          @click.prevent="deleteBulkKeywords()">
                    <i class="fa-solid fa-trash"></i> Delete
                  </button>
                </th>
              </tr>
              <tr>
                <th>
                  <input v-model="allSelected"
                         @change="checkAllSelected"
                         type="checkbox" />
                </th>
                <th class="no-wrap">Keyword Text</th>
                <th class="no-wrap" @click="sortTable('ad_group_name')">
                  Ad Group

                  <span v-if="this.table.sortColumn === 'ad_group_name'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Match Type').show">Match Type</th>
                <th v-if="columns.find(c => c.name === 'Status').show">Status</th>
                <th v-if="columns.find(c => c.name === 'Impressions').show" class="no-wrap" @click="sortTable('impressions')">
                  Impressions

                  <span v-if="this.table.sortColumn === 'impressions'">
                                <i class="fas fa-sort-amount-down-alt"
                                   aria-hidden="true"
                                   v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                   aria-hidden="true" v-else></i>
                              </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Clicks').show" class="no-wrap" @click="sortTable('clicks')">
                  Clicks
                  <span v-if="this.table.sortColumn === 'clicks'">
                                <i class="fas fa-sort-amount-down-alt"
                                   aria-hidden="true"
                                   v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                   aria-hidden="true" v-else></i>
                              </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Cost').show" class="no-wrap" @click="sortTable('cost')">
                  Cost
                  <span v-if="this.table.sortColumn === 'cost'">
                                <i class="fas fa-sort-amount-down-alt"
                                   aria-hidden="true"
                                   v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                   aria-hidden="true" v-else></i>
                              </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Average CPC').show" class="no-wrap" @click="sortTable('cpc')">
                  Average CPC
                  <span v-if="this.table.sortColumn === 'cpc'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Max CPC').show" class="no-wrap" @click="sortTable('max_cpc')">
                  Max CPC
                  <span v-if="this.table.sortColumn === 'max_cpc'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Add To Carts').show" class="no-wrap" @click="sortTable('atc')">
                  Add To Carts
                  <span v-if="this.table.sortColumn === 'atc'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Conversions').show" class="no-wrap" @click="sortTable('conversions')">
                  Conversions
                  <span v-if="this.table.sortColumn === 'conversions'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'Revenue').show" class="no-wrap" @click="sortTable('revenue')">
                  Revenue
                  <span v-if="this.table.sortColumn === 'revenue'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'AMZ Organic Rank').show" class="no-wrap" @click="sortTable('organic_rank')">
                  AMZ Organic Rank
                  <span v-if="this.table.sortColumn === 'organic_rank'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'ACoS').show" class="no-wrap" @click="sortTable('acos')">
                  ACoS
                  <span v-if="this.table.sortColumn === 'acos'">
                    <i class="fas fa-sort-amount-down-alt"
                       aria-hidden="true"
                       v-if="this.table.sortOrder === 'asc'"></i>

                    <i class="fas fa-sort-amount-down"
                       aria-hidden="true" v-else></i>
                  </span>
                </th>
                <th v-if="columns.find(c => c.name === 'KW Tracking').show">KW Tracking ({{ trackingKeywordCount }}/7)</th>
                <th v-if="columns.find(c => c.name === 'Rank History').show">Rank History</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="keyword in sortedKeywords" :key="'keyword_'+keyword.id">
                <td style="width: 3%">
                  <input v-model="selectedKeywords"
                         :value="keyword.id"
                         type="checkbox">
                </td>
                <td class="no-wrap">{{ keywordText(keyword) }}</td>
                <td class="no-wrap">{{ keyword.ad_group_name }}</td>
                <td v-if="columns.find(c => c.name === 'Match Type').show">{{ matchType(keyword) }}</td>
                <td v-if="columns.find(c => c.name === 'Status').show">
                  <div class="btn-group m-h-34" v-if="keyword.status.status">
                    <button type="button" class="btn btn-sm w-80" :class="['btn-' + keyword.status.color]">{{ keyword.status.status }} <i class="mdi mdi-chevron-down"></i></button>
                    <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" :class="['btn-' + keyword.status.color]" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu"
                         v-if="keyword.status.status !== 'REMOVED'"
                         aria-labelledby="dropdownMenuReference4">
                      <a class="dropdown-item"
                         @click.prevent="changeKeywordStatus(2, keyword)"
                         href="#" >Enable</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item"
                         @click.prevent="changeKeywordStatus(3, keyword)"
                         href="#">Pause</a>
                    </div>
                  </div>
                </td>
                <td v-if="columns.find(c => c.name === 'Impressions').show">{{ formatNumber(keyword.impressions) }}</td>
                <td v-if="columns.find(c => c.name === 'Clicks').show">{{ formatNumber(keyword.clicks) }}</td>
                <td v-if="columns.find(c => c.name === 'Cost').show">{{ this.currentCurrency }}{{ formatNumber(keyword.cost, 2) }}</td>
                <td v-if="columns.find(c => c.name === 'Average CPC').show">{{ this.currentCurrency }}{{ formatNumber(keyword.cpc, 2) }}</td>
                <td class="no-wrap" v-if="columns.find(c => c.name === 'Max CPC').show">
                  <div v-if="!editMaxCpc || newMaxCpcForm.keywordId !== keyword.id">
                    ${{ formatNumber(keyword.max_cpc, 2) }}
                    <a href="#"
                       v-if="googleCampaign && googleCampaign.bidding_strategy === 'Manual CPC' && keyword.status.status !== 'REMOVED'"
                       @click.prevent="editMaxCpcHandler(keyword)">
                      <i class="fas fa-edit"></i>
                    </a>
                  </div>
                  <div v-else>
                    <input :disabled="loading"
                           type="text"
                           class="mr-2"
                           style="width: 70px"
                           v-model="newMaxCpcForm.cpc">

                    <a v-if="!loading"
                       class="mr-1 text-danger"
                       @click="editMaxCpc = false">
                      <i class="fas fa-times"></i>
                    </a>

                    <a v-if="!loading"
                       class="text-info"
                       @click="changeMaxCpc(keyword)">
                      <i class="fas fa-save"></i>
                    </a>
                  </div>
                </td>
                <td v-if="columns.find(c => c.name === 'Add To Carts').show">
                  <div class="spinner-border spinner-border-sm"
                       v-if="loadingMetrics"
                       role="status"></div>
                  <span v-else>{{ formatNumber(atc(keyword)) }}</span>
                </td>
                <td v-if="columns.find(c => c.name === 'Conversions').show">
                  <div class="spinner-border spinner-border-sm"
                       v-if="loadingMetrics"
                       role="status"></div>
                  <span v-else>{{ formatNumber(conversions(keyword)) }}</span>
                </td>
                <td v-if="columns.find(c => c.name === 'Revenue').show">
                  <div class="spinner-border spinner-border-sm"
                       v-if="loadingMetrics"
                       role="status"></div>
                  <span v-else>${{ formatNumber(revenue(keyword), 2) }}</span>
                </td>
                <td v-if="columns.find(c => c.name === 'AMZ Organic Rank').show">
                  {{ organicRank(keyword) === '' ? 'N/A' : organicRank(keyword) }}
                </td>
                <td v-if="columns.find(c => c.name === 'ACoS').show">{{ formatNumber(calculateACoS(keyword), 2) }}%</td>
                <td v-if="columns.find(c => c.name === 'KW Tracking').show">
                  <template v-if="keyword.status.status !== 'REMOVED'">
                    <button class="no-wrap btn btn-sm btn-danger"
                            v-if="localKeyword(keyword) && localKeyword(keyword).is_checking"
                            @click="stopTracking(keyword)">Stop Tracking</button>

                    <button class="no-wrap btn btn-sm btn-success"
                            @click="checkKeyword(keyword)"
                            v-else>
                      Start Tracking
                    </button>
                  </template>
                </td>

                <template v-if="columns.find(c => c.name === 'Rank History').show">
                  <td style="height: 40px; padding: 0 10px 0 0; font-size: 10px"
                      v-if="localKeyword(keyword) && localKeyword(keyword).is_checking &&
                       !keyword.message &&
                        localKeyword(keyword).recently_started">
                    Fetching data, this can take up to 48 hours
                  </td>
                  <td v-else-if="localKeyword(keyword) && localKeyword(keyword).is_checking && !keyword.message"
                      style="height: 40px; padding: 0 15px 0 0;">

                    <div style="height: 40px">
                      <canvas :id="'rank-chart-'+campaign.keywords.find(k => k.keyword.toLowerCase() === keyword.text.toLowerCase()).id"
                              @click="$refs.chartPopup.show(localKeyword(keyword))"
                              width="100"></canvas>
                    </div>

                  </td>
                  <td style="height: 40px; padding: 0 10px 0 0; font-size: 10px"
                      v-if="localKeyword(keyword) && localKeyword(keyword).is_checking && keyword.message">
                    {{ keyword.message }}
                  </td>

                  <td style="height: 40px; padding: 0 10px 0 0; font-size: 10px" v-else></td>
                </template>
              </tr>
              <tr>
                <th colspan="5">Summary</th>
                <th>{{ formatNumber(sortedKeywords.reduce((a, b) => a + b.impressions, 0)) }}</th>
                <th>{{ formatNumber(sortedKeywords.reduce((a, b) => a + b.clicks, 0)) }}</th>
                <th>{{ this.currentCurrency + formatNumber(keywords.reduce((a, b) => a + b.cost, 0), 2) }}</th>
                <th colspan="2">{{ this.currentCurrency + (sortedKeywords.length ? formatNumber(sortedKeywords.reduce((a, b) => a + b.cpc, 0) / sortedKeywords.length, 2) : '0.00') }}</th>
                <th>{{ formatNumber(sortedKeywords.reduce((a, b, i) => a + this.atc(sortedKeywords[i]), 0)) }}</th>
                <th>{{ formatNumber(sortedKeywords.reduce((a, b, i) => a + this.conversions(sortedKeywords[i]), 0)) }}</th>
                <th colspan="5">{{ this.currentCurrency + formatNumber(sortedKeywords.reduce((a, b, i) => a + (+this.revenue(sortedKeywords[i])), 0), 2) }}</th>
              </tr>
              </tbody>
            </table>

            <div class="text-center mt-3" v-if="loading">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <add-google-keyword v-if="campaign"
                      @added="added"
                      :campaign="campaign"
                      ref="addGoogleKeyword" />
  <rank-modal ref="rankModal" />

  <export-data-column-selection @export="generateExportFile"
                                ref="exportModal" />

  <chart-popup ref="chartPopup" />

  <google-keyword-table-settings-modal ref="keywordTableSettings" />

  <change-max-cpc-modal ref="changeMaxCpcModal" />

  <google-campaign-modal ref="googleCampaignModal"
                         v-if="campaign"
                         :id="'google_campaign_modal_' + campaign.id" />
</template>

<script>
import ChartPopup from "@/components/rank/ChartPopup.vue";
import AddGoogleKeyword from "@/views/keywords/modals/AddGoogleKeyword";
import RankModal from "@/views/keywords/modals/RankModal.vue";
import ChangeMaxCpcModal from "@/views/keywords/modals/ChangeMaxCpcModal.vue";
import GoogleKeywordTableSettingsModal from "@/views/keywords/GoogleKeywordTableSettingsModal.vue";
import ExportDataColumnSelection from "@/views/keywords/modals/ExportDataColumnSelection.vue";
import {mapGetters} from "vuex";
import getSymbolFromCurrency from "currency-symbol-map";
import GoogleCampaignModal from "@/views/modals/GoogleCampaignModal.vue";

export default {
  components: {
    GoogleCampaignModal, AddGoogleKeyword, RankModal,
    ExportDataColumnSelection, ChartPopup, GoogleKeywordTableSettingsModal,
    ChangeMaxCpcModal},
  data() {
    return {
      selectedKeywords: [],
      allSelected: false,
      adGroups: [],
      dateRange: window.moment().subtract(3, "years").startOf("month").format('MMM D, YY') + " - " + window.moment().format('MMM D, YY'),
      googleCampaign: null,
      editMaxCpc: false,
      amazonCampaigns: [],
      loadingMetrics: false,
      newMaxCpcForm: {
        keywordId: '',
        cpc: '',
        customerId: '',
        adGroupId: '',
      },
      table: {
        sortColumn: 'impressions',
        sortOrder: 'desc',
      },
      campaign: null,
      keywords: [],
      queryParams: {
        ad_group_id: "",
        profileId: this.$route.params.profileId,
        groupBy: "CREATIVE",
        startDate: window.moment().subtract(29, "days").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  watch: {
    amazonAdAccounts(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        setTimeout(() => {
          this.getKeywords();
          this.getAmazonCampaigns();
        }, 200);
      }
    },
    table: {
      handler(val) {
        this.$store.commit('googleKeywordModule/setTable', val);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      columns: 'settings/getKeywordColumns',
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
    currentCurrency() {
      let amazonAccount = this.amazonAdAccounts.find(a => a.profileId == this.queryParams.profileId);
      return amazonAccount ? getSymbolFromCurrency(amazonAccount.currencyCode) : '';
    },
    currentCurrencyCode() {
      let amazonAccount = this.amazonAdAccounts.find(a => a.profileId == this.queryParams.profileId);
      return amazonAccount ? amazonAccount.currencyCode : "";
    },
    trackingKeywordCount() {
      return this.campaign ? this.campaign.keywords.filter(k => this.sortedKeywords.find(sk => sk.text.toLowerCase() === k.keyword.toLowerCase()) && k.is_checking).length : 0;
    },
    sortedKeywords() {
      let c = [...this.keywords];

      if (this.queryParams.ad_group_id) {
        c = c.filter(item  => item.ad_group_id == this.queryParams.ad_group_id)
      }

      if (this.table.sortOrder === 'asc')
        return c.sort((a,b) => a[this.table.sortColumn] - b[this.table.sortColumn]);
      else
        return c.sort((a,b) => b[this.table.sortColumn] - a[this.table.sortColumn]);
    },
  },
  async mounted() {
    this.table = this.$store.state.googleKeywordModule.table;
    this.queryParams.startDate = this.$store.state.dashboardModule.queryParams.startDate;
    this.queryParams.endDate = this.$store.state.dashboardModule.queryParams.endDate;

    this.getAdGroups();
    await this.getCampaignDetails();

    if (this.amazonAdAccounts.length) {
      this.getKeywords();
      this.getAmazonCampaigns();
    }

    this.initDateRange();
  },
  methods: {
    localKeyword(keyword) {
      if (this.campaign) {
        return this.campaign.keywords.find(k => k.keyword.toLowerCase() === keyword.text.toLowerCase() && k.match == keyword.match_type);
      }

      return undefined;
    },
    getGoogleAdDetails() {
      this.$refs.googleCampaignModal.show(this.campaign.google_campaign);
    },
    getAdGroups() {
      this.adGroups = [];

      this.axios.get('/google/campaign/ad-groups', {
        params: {
          campaignId: this.$route.params.id
        }
      }).then((res) => {
        this.adGroups = res.data;
      })
    },
    changeBulkMaxCpc() {
      let keywords = this.sortedKeywords.filter(k => this.selectedKeywords.includes(k.id));
      this.$refs.changeMaxCpcModal.show(keywords);
    },
    changeBulkStatus() {
      this.$swal({
        title: `Are you sure want to pause keyword${this.selectedKeywords.length > 1 ? 's' : ''}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(1,47,245,0.7)',
        confirmButtonText: 'Yes, change it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeKeywordStatus(3);
        }
      });
    },
    deleteBulkKeywords() {
      this.$swal({
        title: `Are you sure want to delete keyword${this.selectedKeywords.length > 1 ? 's' : ''}?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(245,1,1,0.7)',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteKeywords(null);
        }
      });
    },
    checkAllSelected() {
      if (this.allSelected) {
        this.selectedKeywords = this.sortedKeywords.map(d => d.id);
      } else {
        this.selectedKeywords = [];
      }
    },
    editMaxCpcHandler(keyword) {
      this.editMaxCpc = true;
      this.newMaxCpcForm.keywordId = keyword.id;
      this.newMaxCpcForm.cpc = keyword.max_cpc;
      this.newMaxCpcForm.customerId =  keyword.customer_id;
      this.newMaxCpcForm.adGroupId = keyword.ad_group_id;
    },
    changeMaxCpc(keyword) {
      this.loading = true;
      this.axios.post('/google/keywords/change/max-cpc', [this.newMaxCpcForm])
          .then(() => {
            this.editMaxCpc = false;
            keyword.max_cpc = parseFloat(this.newMaxCpcForm.cpc);
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          })
    },
    loadGoogleCampaign(campaignId) {
      this.axios.get('/google/ad-details', {
        params: {
          campaignId: campaignId
        }
      }).then(res => {
        this.googleCampaign = res.data;
      })
    },
    exportData(type) {
      this.$refs.exportModal.show(type);
    },
    generateExportFile(form) {
      let data = [];
      data.push(form.columns);

      this.sortedKeywords.forEach(keyword => {
        let row = [];

        if (form.columns.includes('Keyword Text')) {
          row.push(this.keywordText(keyword));
        }

        if (form.columns.includes('Match Type')) {
          row.push(this.matchType(keyword));
        }

        if (form.columns.includes('Status')) {
          row.push(keyword.status.status);
        }

        if (form.columns.includes('Impressions')) {
          row.push(keyword.impressions+"");
        }

        if (form.columns.includes('Clicks')) {
          row.push(keyword.clicks+"");
        }

        if (form.columns.includes('Cost')) {
          row.push('$' + keyword.cost.toFixed(2));
        }

        if (form.columns.includes('Average CPC')) {
          row.push('$' + keyword.cpc.toFixed(2));
        }

        if (form.columns.includes('Max CPC')) {
          row.push('$' + keyword.max_cpc.toFixed(2));
        }

        if (form.columns.includes('Add To Carts')) {
          row.push(this.atc(keyword)+"");
        }

        if (form.columns.includes('Conversions')) {
          row.push(this.conversions(keyword)+"");
        }

        if (form.columns.includes('Revenue')) {
          row.push('$'+this.revenue(keyword));
        }

        if (form.columns.includes('AMZ Organic Rank')) {
          row.push(this.organicRank(keyword) === '' ? 'N/A' : this.organicRank(keyword));
        }

        if (form.columns.includes('ACoS')) {
          row.push(this.calculateACoS(keyword)+'%');
        }

        data.push(row);
      });

      let formData = {
        data: data,
        type: form.type
      }

      this.axios.post('/google/keywords/export-data', formData, {
        responseType: "arraybuffer",
      }).then((response) => {
        const blob = new Blob([response.data], { type: form.type === 'csv' ? "text/csv" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "(Attribution Insights Keyword Report)" + (this.campaign.asin ? (" " + this.campaign.asin) : "") + " - "+ this.formatCampaignName(this.campaign.google_campaign_name).replace("[ Attribution Insights ] ", "") +" - "+ this.dateRange + "." + form.type;
        link.click();
      });
    },
    added() {
      this.getKeywords();
    },
    keywordText(keyword) {
      if (keyword.match_type === 2) {
        return '['+keyword.text+']';
      } else if (keyword.match_type === 3) {
        return '"'+keyword.text+'"';
      } else {
        return keyword.text;
      }
    },
    matchType(keyword) {
      if (keyword.match_type === 2) {
        return 'Exact';
      } else if (keyword.match_type === 3) {
        return 'Phrase';
      } else {
        return 'Broad';
      }
    },
    calculateACoS(keyword) {
      const purchase = this.revenue(keyword);
      const spend = keyword.cost;

      if (purchase && !isNaN(spend / purchase)) {
        return ((spend / purchase) === Infinity ? 0 : (spend / purchase) * 100).toFixed(2);
      } else
        return 0;
    },
    atc(keyword) {
      if (this.amazonCampaigns.find(c => c.name && (c.name + '').includes(keyword.id) && (c.name + '').includes(this.googleCampaign.id))) {
        return this.amazonCampaigns.filter(c => c.name && (c.name + '').includes(keyword.id) && (c.name + '').includes(this.googleCampaign.id)).reduce((a, b) => a + b.atc, 0);
      } else {
        return 0;
      }
    },
    conversions(keyword) {
      if (this.amazonCampaigns.find(c => c.name && (c.name + '').includes(keyword.id) && (c.name + '').includes(this.googleCampaign.id))) {
        return this.amazonCampaigns.filter(c => c.name && (c.name + '').includes(keyword.id) && (c.name + '').includes(this.googleCampaign.id)).reduce((a, b) => a + b.total_purchase, 0);
      } else {
        return 0;
      }
    },
    revenue(keyword) {
      if (this.amazonCampaigns.find(c => c.name && (c.name + '').includes(keyword.id) && (c.name + '').includes(this.googleCampaign.id))) {
        return this.amazonCampaigns.filter(c => c.name && (c.name + '').includes(keyword.id) && (c.name + '').includes(this.googleCampaign.id)).reduce((a, b) => a + b.total_sale, 0).toFixed(2);
      } else {
        return 0;
      }
    },
    organicRank(keyword) {
      if (this.campaign) {
        let key = this.campaign.keywords.find(k => k.keyword.toLowerCase() === keyword.text.toLowerCase());

        if (key) {
          return key.current_position;
        }
      }

      return '';
    },
    checkKeyword(key) {
      if (this.trackingKeywordCount >= 7) {
        this.$swal.fire('You can track maximum 7 keywords', '', 'info')
        return;
      }

      this.$swal({
        title: 'Are you sure you want to track this keyword?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(1,47,245,0.7)',
        confirmButtonText: 'Yes, track it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let keyword = this.campaign.keywords.find(k => k.keyword.toLowerCase() === key.text.toLowerCase())
          let keywordId = null;

          if (keyword) {
            keywordId = keyword.id;
          }

          this.axios.post('keyword-rank', {
            id: keywordId,
            amazon_ad_id: this.campaign.id,
            match_type: key.match_type,
            keyword: key.text,
          }).then((res) => {
            if (keyword) {
              keyword.is_checking = 1;
              this.getRankChartData(keyword);
            } else {
              this.campaign.keywords.push(res.data.data);
            }
          }).catch(err => {
            this.showFailMsg(err.response.data.message);
          });
        }
      });
    },
    stopTracking(key) {
      this.$swal({
        title: 'Are you sure you want to stop tracking?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(245,1,29,0.7)',
        confirmButtonText: 'Yes, stop it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let keyword = this.campaign.keywords.find(k => k.keyword.toLowerCase() === key.text.toLowerCase())

          this.axios.delete('keyword-rank/' + keyword.id)
              .then(() => {
                keyword.is_checking = 0;
              });
        }
      });
    },
    sortTable(col) {
      let order = 'asc';

      if (col === this.table.sortColumn) {
        if (this.table.sortOrder === 'asc')
          order = 'desc';
      }

      this.table.sortColumn = col;
      this.table.sortOrder = order;
    },
    initDateRange() {
      let t = window.moment(this.$store.state.dashboardModule.queryParams.startDate, 'YYYYMMDD');
      let a = window.moment(this.$store.state.dashboardModule.queryParams.endDate, 'YYYYMMDD');

      let self = this;

      window.$('#reportrange span').html(self.dateRange);

      window.$("#reportrange").daterangepicker({
            startDate: t,
            endDate: a,
            locale: {
              format: 'MMM D, YYYY'
            },
            ranges: {
              Today: [window.moment(), window.moment()],
              Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
              "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
              "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
              "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
              "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
              "All Time": [window.moment().subtract(3, "years").startOf("month"), window.moment()],
            },
          },
          function (t, a) {
            self.queryParams.startDate = t.format('YYYYMMDD');
            self.queryParams.endDate = a.format('YYYYMMDD');
            self.dateRange = t.format('MMM D, YYYY') + ' - ' + a.format('MMM D, YYYY');
            window.$('#reportrange span').html(self.dateRange);
            self.getKeywords();
            self.getAmazonCampaigns();
          }
      )
    },
    deleteKeywords(keyword = null) {
      let payload = [];

      if (keyword) {
        payload.push({
          id: keyword.id,
          customerId: keyword.customer_id,
          adGroupId: keyword.ad_group_id
        });
      } else {
        payload = this.sortedKeywords
            .filter(k => this.selectedKeywords.includes(k.id))
            .map(keyword => ({
              id: keyword.id,
              customerId: keyword.customer_id,
              adGroupId: keyword.ad_group_id
            }));
      }

      this.axios.post('/google/keyword/delete', payload)

      setTimeout(() => {
        if (keyword) {
          this.keywords = this.keywords.filter(k => k.id !== keyword.id);
        } else {
          this.keywords = this.keywords.filter(k => !this.selectedKeywords.includes(k.id));
        }
      }, 100)
    },
    changeKeywordStatus(status, keyword = null) {
      let payload = [];

      if (keyword) {
        payload.push({
          status: status,
          id: keyword.id,
          customerId: keyword.customer_id,
          adGroupId: keyword.ad_group_id
        });
      } else {
        payload = this.sortedKeywords
            .filter(k => this.selectedKeywords.includes(k.id))
            .map(keyword => ({
              status: status,
              id: keyword.id,
              customerId: keyword.customer_id,
              adGroupId: keyword.ad_group_id
            }));
      }

      this.axios.post('/google/keyword/change-status', payload)

      setTimeout(() => {
        if (keyword) {
          keyword.status = this.getGoogleCampaignStatus(status);
        } else {
          this.sortedKeywords.filter(k => this.selectedKeywords.includes(k.id))
              .forEach(keyword => {
                keyword.status = this.getGoogleCampaignStatus(status);
              })
        }
      }, 100)
    },
    async getCampaignDetails() {
      await this.axios.get('/google/campaign/' + this.$route.params.id)
          .then(res => {
            this.campaign = res.data.data;
            this.loadGoogleCampaign(this.campaign.google_campaign)
          })
    },
    getRankChartData(keyword) {
      const campaignKeyword = this.localKeyword(keyword);

      if (campaignKeyword && campaignKeyword.is_checking) {
        this.axios.get('/keyword-rank/' + campaignKeyword.id)
            .then((res) => {
              if (res.data.data.data.length) {
                this.initChart(res.data.data, 'rank-chart-' + campaignKeyword.id);
              } else {
                keyword.message = "Not indexed or ranking too low";
              }
            });
      }
    },
    initChart(data, id) {
      this.chartData = data;
      const ctx = document.getElementById(id).getContext('2d');
      this.chart = new window.Chart(ctx, {
        type: 'line',
        data: {
          labels:data.labels,
          datasets: [{
            label: 'Rank',
            data: data.data,
            borderColor: '#e9b02b',
            backgroundColor: [
              '#e9b02b'
            ],
            borderWidth: ``,
            fill: "start",
            tension: 0.2
          }]
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            point:{
              backgroundColor: '#e30e27'
            }
          },
          plugins: {
            tooltip: {
              titleSpacing: 1,
              titleFont: {
                weight: 'bold',
                size: 10
              },
              bodyFont: {
                size: 8
              }
            },
            legend: {
              display: false
            },
          },
          scales: {
            y: {
              display: false,
              reverse: true,
              ticks: {
                display: false,
              }
            },
            x: {
              display: !data.data.length,
              ticks: {
                display: false,
              }
            }
          }
        }
      });
    },
    getKeywords() {
      this.keywords = [];
      this.loading = true;

      this.axios.post('/google/keywords', {
        campaignId: this.$route.params.id,
        startDate: this.queryParams.startDate,
        endDate: this.queryParams.endDate,
        currency: this.currentCurrencyCode,
      }).then(res => {
        this.keywords = res.data
        this.keywords.forEach(keyword => {
          keyword.status = this.getGoogleCampaignStatus(keyword.status)
          this.getRankChartData(keyword);
        })

      }).catch((err) => {
        this.showFailMsg(err.response.data.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    getAmazonCampaigns() {
      this.loadingMetrics = true;
      this.amazonCampaigns = [];

      this.axios.get('/ads/amazon/campaign', {
        params: this.queryParams
      }).then(res => {
        this.amazonCampaigns = res.data.data;

        this.keywords.forEach(keyword => {
          keyword.atc = this.atc(keyword);
          keyword.conversions = this.conversions(keyword);
          keyword.revenue = this.revenue(keyword);
          keyword.organic_rank = this.organicRank(keyword);
          keyword.acos = this.calculateACoS(keyword);
        })
      }).finally(() => {
        this.loadingMetrics = false;
      })
    }
  }
}
</script>