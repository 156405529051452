<template>
  <div class="modal fade" id="modal-rank" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-add-keywordLabel">Keyword Rank - {{ keyword ? keyword.keyword : '' }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height: 400px">
          <canvas id="rank-chart" class="drop-shadow" height="40" width="100%"></canvas>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      keyword: null,
      fetchingData: false,
      chart: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-rank'))
  },
  methods: {
    show(keyword) {
      this.keyword = keyword;

      this.loadData(keyword.id);

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadData(keywordId) {
      this.fetchingData = true;

      if (this.chart) {
        this.chart.destroy();
      }

      this.axios.get('/keyword-rank/' + keywordId)
          .then((res) => {
            this.initChart(res.data.data);
          })
          .finally(() => {
            this.fetchingData = false;
          })
    },
    initChart(data) {
      const ctx = document.getElementById('rank-chart').getContext('2d');
      this.chart = new window.Chart(ctx, {
        type: 'line',
        plugins: [window.ChartDataLabels],
        data: {
          labels:data.labels,
          datasets: [{
            label: 'Rank',
            data: data.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function(value) {
                return '#' + value;
              },
              font: {
                weight: 'bold',
              }
            }
          },
          scales: {
            y: {
              reverse: true,
              ticks: {
                padding: 20,
                stepSize: 1
              }
            },
            x: {
              ticks: {
                padding: 20
              }
            }
          }
        }
      });
    }
  }
}
</script>