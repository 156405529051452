<template>
  <div class="modal fade" id="modal-change-max-cpc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-add-keywordLabel">Change Max CPC</h5>
        </div>
        <div class="modal-body">
          <table class="table table-bordered">
            <tbody>
            <tr :class="{'bg-light-default': form.type === 'increase'}">
              <td>
                <input type="radio"
                       v-model="form.type"
                       name="type"
                       :disabled="loading"
                       value="increase"> Increase
              </td>
              <td>
                <input name="increase_type"
                       value="percentage"
                       :disabled="form.type !== 'increase' || loading"
                       v-model="form.increase_type"
                       type="radio"> Percentage
                <input type="radio"
                       class="ml-3"
                       :disabled="form.type !== 'increase' || loading"
                       name="increase_type"
                       value="fixed"
                       v-model="form.increase_type"> Fixed
              </td>
              <td>
                <input class="form-control form-control-sm"
                       :disabled="form.type !== 'increase' || loading"
                       v-model="form.increase_amount"
                       placeholder="Amount"
                       min="0"
                       type="number">
              </td>
            </tr>
            <tr :class="{'bg-light-default': form.type === 'decrease'}">
              <td>
                <input type="radio"
                       name="type"
                       :disabled="loading"
                       v-model="form.type"
                       value="decrease"> Decrease
              </td>
              <td>
                <input name="decrease_type"
                       value="percentage"
                       :disabled="form.type !== 'decrease' || loading"
                       v-model="form.decrease_type"
                       type="radio"> Percentage
                <input type="radio"
                       class="ml-3"
                       :disabled="form.type !== 'decrease' || loading"
                       name="decrease_type"
                       value="fixed"
                       v-model="form.decrease_type"> Fixed
              </td>
              <td>
                <input class="form-control form-control-sm"
                       :disabled="form.type !== 'decrease' || loading"
                       v-model="form.decrease_amount"
                       placeholder="Amount"
                       min="0"
                       type="number">
              </td>
            </tr>
            <tr :class="{'bg-light-default': form.type === 'fixed'}">
              <td>
                <input type="radio"
                       name="type"
                       :disabled="loading"
                       v-model="form.type"
                       value="fixed"> Fixed
              </td>
              <td></td>
              <td>
                <input class="form-control form-control-sm"
                       :disabled="form.type !== 'fixed' || loading"
                       v-model="form.fixed_amount"
                       placeholder="Amount"
                       min="0"
                       type="number">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button"
                  :disabled="loading"
                  class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
          <button type="button"
                  :disabled="loading"
                  @click.prevent="saveMaxCpc"
                  class="btn btn-primary btn-rounded my-2">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: [],
      modal: null,
      loading: false,
      form: {
        keywords: [],
        type: 'increase',
        increase_type: 'percentage',
        increase_amount: '',
        decrease_type: 'percentage',
        decrease_amount: '',
        fixed_amount: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-change-max-cpc'), {
      backdrop: 'static'
    })
  },
  methods: {
    resetForm() {
      this.form = {
        keywords: [],
        type: 'increase',
        increase_type: 'percentage',
        increase_amount: '',
        decrease_type: 'percentage',
        decrease_amount: '',
        fixed_amount: ''
      };
    },
    show(keywords) {
      this.resetForm();
      this.keywords = keywords;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    saveMaxCpc() {
      this.loading = true;
      const payload = this.keywords.map(keyword => {
        let cpc = keyword.max_cpc;

        if (this.form.type === 'fixed') {
          cpc = this.form.fixed_amount;
        } else if (this.form.type === 'increase') {
          if (this.form.increase_type === 'fixed') {
            cpc += +this.form.increase_amount;
          } else {
            cpc += cpc * (+this.form.increase_amount / 100)
          }
        } else if (this.form.type === 'decrease') {
          if (this.form.decrease_type === 'fixed') {
            cpc -= +this.form.decrease_amount;
          } else {
            cpc -= cpc * (+this.form.decrease_amount / 100)
          }
        }

        return {
          keywordId: keyword.id,
          cpc: cpc,
          customerId: keyword.customer_id,
          adGroupId: keyword.ad_group_id
        }
      });

      this.axios.post('/google/keywords/change/max-cpc', payload)
          .then(() => {
            this.keywords.forEach(keyword => {
              const newKeyword = payload.find(p => p.keywordId === keyword.id);

              keyword.max_cpc = newKeyword.cpc;
            });

            this.hide();
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>