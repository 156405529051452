<template>
  <div class="modal fade" id="modal-google-add-keyword" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modal-google-add-keywordLabel">Add Keyword</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
              </div>      
              <div class="modal-body">
                <div class="form-group mb-3">
                  <label>Keyword</label>
                  <select class="select2 keywords form-control"
                          style="width: 100%"
                          multiple="multiple"
                          v-model="keywordForm.keywords"
                          data-placeholder="Enter Keywords">
                  </select>
                  <v-errors :errors="errorFor('keywords')" />
                </div>
                <div class="form-group mb-3">
                  <label>Match</label>
                  <select class="form-select form-select-sm form-control"
                          v-model="keywordForm.match">
                    <option value="2">Exact</option>
                    <option value="3">Phrase</option>
                    <option value="4">Broad</option>
                  </select>
                </div>
                <div class="form-group mb-3">
                  <label>Status</label>
                  <select class="form-select form-select-sm form-control"
                          v-model="keywordForm.status">
                    <option value="PAUSED">PAUSED</option>
                    <option value="ACTIVE">ACTIVE</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary btn-rounded my-2"
                  :disabled="loading"
                  @click.prevent="submit">Save changes
                </button>
                <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      keywordForm: {
        keywords: '',
        status: 'PAUSED',
        match: '2'
      }
    }
  },
  props: {
    campaign: Object
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-google-add-keyword'))
  },
  methods: {
    initKeywordsSelect2() {
      let self = this;

      window.$('.keywords').select2({
        tokenSeparators: ',',
        tags: true
      });

      window.$('.keywords').on('change', function () {
        self.keywordForm.keywords = window.$(".keywords").select2("val");
      });
    },
    show() {
      this.modal.show();

      setTimeout(() => {
        this.initKeywordsSelect2();
      }, 500)
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.keywordForm.campaign_id = this.campaign.google_campaign;
      this.keywordForm.google_manager_id = this.campaign.google_manager_id;
      this.keywordForm.google_account_id = this.campaign.google_account_id;

      this.axios.post('/google/add-keywords', this.keywordForm)
        .then(() => {
          this.hide();
          this.$emit('added')
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>