<template>
  <div class="modal fade" id="modal-export-keyword-data" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Columns</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul class="list-group">
            <li class="list-group-item"
                v-for="(col, i) in columns"
                :key="'columns_'+i">
              <div class="form-check">
                <input class="form-check-input"
                       v-model="form.columns"
                       type="checkbox"
                       :value="col"
                       :id="'column_'+i">
                <label class="form-check-label"
                       :for="'column_'+i">
                  {{ col }}
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-rounded my-2"
                  :disabled="loading"
                  @click.prevent="submit">Export
          </button>
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        type: '',
        columns: []
      },
      columns: ['Keyword Text', 'Match Type', 'Status', 'Impressions',
        'Clicks', 'Cost', 'Average CPC', 'Max CPC', 'Add To Carts', 'Conversions',
        'Revenue', 'AMZ Organic Rank', 'ACoS']
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-export-keyword-data'))
  },
  methods: {
    show(type) {
      this.form.columns = [...this.columns];
      this.form.type = type;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.$emit('export', this.form);
      this.hide();
    }
  }
}
</script>